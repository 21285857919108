
import request from '@/utils/request'
import qs from 'qs'

// 期刊驳回
export function orderreject (id,reject) {
  let data = {
   id,reject
  }
  data = qs.stringify(data)
  return request({
    url: '/qikan/Reject',
    method: 'post',
    data
  })
}
//后期补传用稿通知
export function laterupload (id) {
  let data = {
   id
  }
  data = qs.stringify(data)
  return request({
    url: '/qikan/NOUploadNotice',
    method: 'post',
    data
  })
}

