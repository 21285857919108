<template>
	<div>
		<div class="qkxx_title">
			初审稿件
		</div>
		<div style="padding: 20px;">
			<el-form ref="form" :rules="rules" :model="form" label-width="100px" label-position="top" >
				<el-form-item label="驳回理由"  prop="reason">
					<el-input id="rejectReason" v-model="form.reason" type="textarea" placeholder="请输入驳回理由"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="danger" size="big" @click="submitForm('form')">驳回</el-button>
					<el-button size="big" @click="goback()">取消</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import{orderreject}from "../../../API/QKD/check.js"
	export default {
		data() {
			return {
				form: {
					reason: '',

				},
				rules: {
					reason: [{
						required: true,
						message: '请输入驳回理由',
						trigger: 'blur'
					}]
				}
			}
		},methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if(valid) {
						let wid=this.$route.query.wid;
					  console.log(this.form.reason)
					  orderreject(wid,this.form.reason).then(res=>{
					 if(res.data.code==0){
						 this.$message.success("驳回成功")
						let id = this.$route.query.id
						if(id==4){
							this.$router.push("/Periodical/firstcheck/?id=4")
						}else if(id==5){
							this.$router.push("/Periodical/secondcheck/?id=5")
						}
						 
					 }else{
						this.$message.error(res.data.msg)
					 }
					  })
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},goback(){
				history.back()
			}
		}
	}
</script>

<style>
	#rejectReason {
		resize: none;
		height: 100px;
	}
</style>